<template>
  <section
    id="about"
    class="flex flex-col xl:flex-row justify-center items-center w-full relative z-10 min-h-screen bg-white"
  >
    <div class="block md:justify-center md:w-3/5 w-full mt-16">
      <h2
        class="m-7 text-4xl md:text-5xl border-b-4 md:border-b-0 p-1 font-bold"
      >
        Qui suis-je ?
      </h2>
      <h3 class="m-7 text-2xl md:text-4xl italic">
        Un étudiant passionné de développement
      </h3>
      <p class="m-7 md:m-auto text-base italic md:w-1/2">
        Je m'appelle Rémi, j'ai 25 ans, titulaire d'un bachelor d'école de
        commerce option Marketing (Webmarketing), je suis actuellement en
        formation de développeur web chez OpenClassrooms. Je recherche une
        alternance pour commencer ma prochaine formation : Developpeur frontend
        JavaScript et React.
      </p>
      <p class="m-7 md:m-auto text-base italic md:w-1/2">
        Ayant toujours été passionné par l'informatique, j'ai décidé de me
        lancer dans cette formation afin de me spécialiser dans le développement
        web, et de pouvoir exercer un métier qui me passionne.
      </p>
      <article class="justify-center wrap">
        <h3 class="m-7 text-2xl md:text-3xl font-bold italic">Mon CV</h3>
        <a
          href="https://github.com/Osiri70cl/RemiP/blob/main/CV_Remi_PINCE.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          CV-Rémi.P.pdf
        </a>
      </article>
    </div>
    <div class="sm:w-2/5 sm:visible invisible sm-2 p-4">
      <img
        src="../images/paupy.jpg"
        alt="photo des pyrénées"
        class="w-full h-full object-contain border-2 border-black"
      />
    </div>
  </section>
</template>

<script></script>
